import React, { useState, useEffect } from "react";
import { getTenantInfo } from "@/apis/settlement";
import "./css/index.scss";

const Footer = (prop: any) => {
  const [copyright, setCopyRight] = useState("");
  const [icpRecord, setIcpRecord] = useState("湘ICP备2021010619号-26"); //icp备案
  const [filingNumber, setFilingNumber] = useState("京公安备11010602000551号"); //公安备案信息
  const [number, setNumber] = useState();
  const [licence, setLicence] = useState("https://assets.wangxiao.net/images/pc/yyzz.png"); //营业执照5555555
  const { backgroundColor, paddingStyle, copyrightName, color } =
    prop.data || {};
  const { paddingBottom, paddingLeft, paddingTop, paddingRight } =
    paddingStyle || {};
  const style = {
    backgroundColor: backgroundColor,
    color: color,
    paddingTop,
    paddingRight,
    paddingLeft,
    paddingBottom,
  };
  console.log(style,'rrrrr');
  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const response = await getTenantInfo();
        if (response) {
          setCopyRight(response.copyright);
          if(response.icpRecord){
            setIcpRecord(response.icpRecord);
          }
          if (response.filingNumber) {
            setFilingNumber(response.filingNumber);
          }
          setNumber(response.number);
          if(response.licence){
            setLicence(response.licence);
          }
          
          // setFilingNumber(response.filingNumber);
        }
      } catch (error) {
        console.error("获取失败", error);
      }
    };

    fetchLogo();
  }, []);

  // 打开一个新窗口，并将图片的URL设置为新窗口的内容
  const openImageInNewWindow = () => {
    console.log(licence, "2222");

    var newWindow = window.open("", "_blank");
    if (newWindow) {
      // 设置新窗口的文档标题
      newWindow.document.title = "图片查看器";
      // 创建一个图片元素并添加到新窗口的文档中
      var img = newWindow.document.createElement("img");
      img.src = licence;
      // 将图片元素添加到body中
      newWindow.document.body.appendChild(img);
    } else {
      alert("窗口打开失败，请允许弹出窗口。");
    }
  };
  return (
    <div className="footer" style={style}>
      <div className="footer-content">
        <p className="footer-first">
          Copyright © {copyrightName ? copyrightName : copyright}.
        </p>
        <p className="footer-second">
          <a
            href="https://beian.miit.gov.cn/#/Integrated/index"
            target="_blank"
            rel="noopener noreferrer"
          >
            {icpRecord}
          </a>
          <span>
            <img
              className="police-logo"
              src={require("./image/gongan.png")}
              alt=""
            />
            <a
              href={
                "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=" +
                number
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              {filingNumber}
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                openImageInNewWindow();
              }}
            >
             营业执照
            </a>
            
          </span>
          
        </p>
      </div>
    </div>
  );
};

export default Footer;
