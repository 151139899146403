export const PROJECT_PREFIX = "zdyx-"; // 项目统一前缀

export const ORG_PREFIX = window.location.hostname; // 机构统一前缀

export const LOGO_URL = "logo"; // 机构logo

export const CHECK_AUTO_LOGIN = PROJECT_PREFIX + "CHECK_AUTO_LOGIN"; // 是否自动登录

export const TOKEN_NAME = PROJECT_PREFIX + "TOKEN"; // 用户token

export const PAGE_DATA = PROJECT_PREFIX + "PAGE_DATA"; // 面包屑数据

export const LOGIN_USER_INFO = PROJECT_PREFIX + "LOGIN_USER_INFO"; // 登录用户信息

export const WX_PUB_QR = "wx_pub_qr"; // 微信二维码支付

export const ALIPAY_PC_DIRECT = "alipay_pc_direct"; // 支付宝支付

export const TARGET_TENANT_ID = "target-tenant-id"; // 供应商id

// 1000 * 60 * 60 * 24 * 7
export const TOKEN_EXPIRE = 604800000; // TOKEN-有效期7天

// 1000 * 60 * 60 * 24
export const TOKEN_TEMP_EXPIRE = 86400000; // TOKEN-有效期24小时

export const ENV_DEV = "dev"; // dev环境
export const ENV_TEST = "test"; // test环境
export const ENV_PROD = "prod"; // prod环境

export const NOT_MODIFIED_CODE = 304; // 缓存code

export const SUCCESS_CODE = 1000; // 成功
export const UNLOGIN_CODE = 1010; // token失效

// 店铺装修
export const HOME_PAGE_CODE = "home"; // 首页-页面code
export const WEB_PAGE_CODE = "webPage"; // 微页面-页面code

// 需要加供应商id的地址白名单
export const SUPPLIERID_WHITE_LIST = [
  "/trade//order/contract/authentication",
  "/shelves/commodity",
  "/shelves/v2/commodity",
  "/shelves/my/sku/lessons",
  "/shelves/my/lesson",
  "/shelves/my/lesson1",
  "/shelves/commodity/getStudyStat",
  "/shelves/my/v2/lesson/authorize",
  "/resource/lesson",
  "/resource/lesson/get/Propertice/types",
  "/resource/lesson/get/questions",
  "/resource/lesson/review",
  "/resource/lesson/submit",
  "/resource/lesson/analysis",
  "/resource/lesson/do/wrong",
  "/resource/lesson/certificate/exam/analysis",
  "/resource/lesson/analysis/detail",
  "/resource/lesson/exam/pause",
  "/resource/lesson/exam/start",
  "/resource/video/heartbeat",
  "/resource/question/record",
  "/resource/material/record",
  "/resource/material/study/record",
  "/resource/live/record",
  "/resource/lesson/live/replay/record",
  "/resource/lesson/live/replay",
  "/resource/lesson/exam",
  "/trade/order",
  "/trade/v2/order",
  "/trade/order/detail",
  "/trade/v2/order/detail",
  "/shelves/commodity/products",
  "/trade/order/contract/sign",
  "/trade/order/contract/authentication",
  "/trade/order/contract/info",
  "/trade/payment",
  "/trade/v2/payment",
  "/trade/order/cancel",
  "/trade/order/address",
  "/trade/order/refund",
  "/trade/order/refund/cancel",
  "/trade/order/negotiations",
  "/trade/order/received",
  "/resource/my/mistakes/remove",
  "/resource/mistakes/submit",
  "/resource/my/collect",
  "/resource/collection/remove",
  "/resource/collection/check",
  "/resource/collect/mistake",
  "/resource/collect/submit",
  "/shelves/commodity/",
  "/trade/v2/payment/request",
  "/shelves/commodity/getCommodityStudyStat",
  "/trade/v2/order/count",
  "/shelves/v2/commodity/blocks",
  "/shelves//commodity/getProductTreeNodeSpeedStat",
];
