import { useLocation } from "react-router-dom";
import RecursionTree from "@/components/GoodsShow/RecursionTree";
import { getCommodityTypes } from "@/apis/courseDetail";
import "./index.scss";
import { useEffect, useState } from "react";

// 查看更多
function GoodsShowMore() {
  const {
    state: { params, config },
  } = useLocation();
  // console.log(params, config, "params---");
  const [newData, setNewData] = useState<any>({ ...config, showMore: false });
  // const [newParams,setNewParams]=useState({})

  const getGoodsShowTypes = async (params: any) => {
    const tabList: { name: string; value: number; key: string }[] = [];
    params.limit = 999;
    const res = await getCommodityTypes({
      ...params,
      nodeType: "P",
    });
    if (res && res.length > 0) {
      res.map((item: any) => {
        switch (item.type) {
          case 0:
            tabList.push({ name: "资料", value: 0, key: item.type });
            break;
          case 1:
            tabList.push({ name: "视频", value: 1, key: item.type });
            break;
          case 2:
            tabList.push({ name: "题库", value: 2, key: item.type });
            break;
          case 4:
            tabList.push({ name: "直播", value: 4, key: item.type });
            break;
        }
      });
    }
    const result = {
      ...config,
      types: tabList || [],
      progressInfo: res,
      showMore: false,
    };
    setNewData(result);
  };
  useEffect(() => {
    if (config && params) {
      getGoodsShowTypes(params);
    }
  }, [config, params]);

  return (
    <div className="goodsshow-more-container">
      <RecursionTree params={params} config={{ ...newData }} />
    </div>
  );
}
export default GoodsShowMore;
